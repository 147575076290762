/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-v30-latin-300.woff2') format('woff2'), url('../fonts/roboto-v30-latin-300.woff') format('woff');
    font-display: swap;
}
/* roboto-300italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/roboto-v30-latin-300italic.woff2') format('woff2'), url('../fonts/roboto-v30-latin-300italic.woff') format('woff');
    font-display: swap;
}
/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v30-latin-regular.woff2') format('woff2'), url('../fonts/roboto-v30-latin-regular.woff') format('woff');
    font-display: swap;
}
/* roboto-italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/roboto-v30-latin-italic.woff2') format('woff2'), url('../fonts/roboto-v30-latin-italic.woff') format('woff');
    font-display: swap;
}
/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/roboto-v30-latin-500.woff2') format('woff2'), url('../fonts/roboto-v30-latin-500.woff') format('woff');
    font-display: swap;
}
/* roboto-500italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/roboto-v30-latin-500italic.woff2') format('woff2'), url('../fonts/roboto-v30-latin-500italic.woff') format('woff');
    font-display: swap;
}
/* roboto-condensed-300 - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-condensed-v25-latin-300.woff2') format('woff2'), url('../fonts/roboto-condensed-v25-latin-300.woff') format('woff');
    font-display: swap;
}
/* roboto-condensed-300italic - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/roboto-condensed-v25-latin-300italic.woff2') format('woff2'), url('../fonts/roboto-condensed-v25-latin-300italic.woff') format('woff');
    font-display: swap;
}
/* roboto-condensed-regular - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-condensed-v25-latin-regular.woff2') format('woff2'), url('../fonts/roboto-condensed-v25-latin-regular.woff') format('woff');
    font-display: swap;
}
/* roboto-condensed-italic - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/roboto-condensed-v25-latin-italic.woff2') format('woff2'), url('../fonts/roboto-condensed-v25-latin-italic.woff') format('woff');
    font-display: swap;
}

/* Black Diamond W00 Regular */
@font-face{
    font-family:"Black Diamond";
    font-weight: 400;
    src: url("../fonts/black-diamond.woff2") format("woff2"), url("../fonts/black-diamond.woff") format("woff");
    font-display: swap;
}
