.btn {
    &.btn-primary {
        --bs-btn-border-color: #{$primary};
        --bs-btn-hover-border-color: #{$primary};
        --bs-btn-active-border-color: #{$primary};
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
    &.btn-secondary {
        --bs-btn-color: #{$primary};
        --bs-btn-border-color: #{$primary};
        --bs-btn-hover-border-color: #{$primary};
        --bs-btn-active-border-color: #{$primary};
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
    &.btn-block {
        width: 100%;
    }
}
