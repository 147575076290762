// teaser text element with arrow and line below
.frame-type-text {
    &.frame-layout-1 {
        padding: 1rem 0;
        border-bottom: 1px solid $gray-dark;
        &:last-of-type {
            border-bottom: none;
        }
        h2,h3,h4 {
            &::after {
                // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
                font-family: 'iconmanta';
                content: '\e907';
                -webkit-font-smoothing: antialiased;
                float: right;
                font-size: 1.5rem;
            }
        }
    }
    &.frame-layout-2,
    &.frame-layout-3 {
        padding: 2rem 2rem 1rem;
    }
    &.frame-layout-2 {
        background-color: $blue-bg;
    }
    &.frame-layout-3 {
        background-color: $green-bg;
    }
}
.frame-default {
    margin-bottom: 4.5rem;

    &.frame-type-text,
    &.frame-type-list,
    &.frame-type-html {
        margin-bottom: 0;
    }
    &.frame-type-text {
        &.frame-layout-2,
        &.frame-layout-3 {
            margin-bottom: 2rem;
        }
    }
}
.frame-mb-2 {
    margin-bottom: 2rem;
}
.frame-mb-3 {
    margin-bottom: 3rem;
}
.frame-mb-4 {
    margin-bottom: 4.5rem;
}

ul.list-checker {
    padding-left: 0;
    list-style: none;

    li {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        margin-left: 1.5rem;
        font-weight: 400;

        &::before {
            position: absolute;
            margin-top: 0;
            margin-left: -1.5rem;
            // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
            font-family: 'iconmanta';
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            line-height: 1;
            text-transform: none;
            content: '\e92f';
            -webkit-font-smoothing: antialiased;
        }
    }
}

/* TYPO3 CE */
// von https://blog.teamgeist-medien.de/2016/04/typo3-responsive-images-mit-fluid-styled-content-fsc.html

/* Gallerie bis 992px auf volle Breite */
@media (max-width: 992px) {
    .ce-gallery {
        width: 100%;
    }
};

/* Anpassungen von kleinen bis groeßeren Geraeten */
@media (min-width: 640px) {
    .ce-gallery .ce-column {
        margin: 0;
        /* Abstand zwischen Bildern */
        padding: 0 5px;
        box-sizing: border-box;
    }
    /* Kein Abstand beim ersten und letzten Bild */
    .ce-gallery .ce-column:first-child {
        padding-left: 0;
    }
    .ce-gallery .ce-column:last-child {
        margin-right: 0;
    }
    /* Fluid Image Tags */
    .ce-gallery img,
    .ce-gallery picture {
        width: 100%;
        height: auto;
    }
    /* Spaltenbreiten je nach eingestellten Columns */
    .ce-gallery[data-ce-columns="2"] .ce-column {
        width: 50%;
    }
    .ce-gallery[data-ce-columns="3"] .ce-column {
        width: 33%;
    }
    .ce-gallery[data-ce-columns="4"] .ce-column {
        width: 25%;
    }
    .ce-gallery[data-ce-columns="5"] .ce-column {
        width: 20%;
    }
    .ce-gallery[data-ce-columns="6"] .ce-column {
        width: 33%;
    }
};

@media (min-width: 992px) {
    .ce-gallery[data-ce-columns="6"] .ce-column {
        width: 16.66%;
    }
}

/* Anpassungen fuer kleine Geraete */
@media (max-width: 640px) {  /* Ein Bild pro Zeile */
    .ce-gallery .ce-column {
        margin: 0 0 10px;
        width: 100%;
        box-sizing: border-box;
    }
    /* Fluid Image Tags */
    .ce-gallery img,
    .ce-gallery picture {
        width: 100%;
        height: auto;
    }
};