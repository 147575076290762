// SimpleTeasers and TravelArea page (region list)
//
ul.nav-teasers {
    @extend .row;
    .nav-item {
        .card-layout {
            padding: 0;
            margin-bottom: 3rem;
        }
    }
    &.nav-teasers-default {
        .nav-item {
            @extend .col-12;
            @extend .col-sm-6;
            @extend .col-lg-4;
        }
    }
    &.nav-teasers-2 {
        .nav-item {
            @extend .col-12;
            @extend .col-sm-6;
        }
    }
    &.nav-teasers-4 {
        .nav-item {
            @extend .col-12;
            @extend .col-sm-6;
            @extend .col-lg-3;
        }
    }
    &.nav-teasers-region {
        .nav-item {
            @extend .col-6;
            @extend .col-md-4;
        }
    }
    .nav-img {
        img {
            width: 100%;
            height: auto;
            margin-bottom: 1.5rem;
        }

    }
    .nav-link {
        padding: 0;
    }
}

// Teasers
.card {
    a {
        text-decoration: none;
    }
    // Simple Teaser, Crosslink Teaser Menu Pages, Carousel Teaser Textpic and Marketing Teaser
    &.card-selection {
        margin-bottom: 3rem;
        .card-body {
            padding: 1.5rem 0 0;
            p {
                color: $gray-darker;
                margin-bottom: 0;
            }
            h5 {
                font-family: $font-family-headlines;
                font-size: 1.75rem;
                font-weight: $font-weight-normal;
                text-transform: uppercase;
                margin-bottom: 0;
                ~p {
                    margin-top: 0.5rem;
                }
            }
        }
        // Simple Teaser, Overlay
        &.layout-1 {
            position: relative;
            .card-body {
                position: absolute;
                left: 2rem;
                right: 2rem;
                bottom: 2rem;
            }
            a,h5,p {
                color: #fff;
            }
            .card-img {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    left: 0; top: 0;
                    width: 100%; height: 100%;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.54) 100%)
                }
            }
        }
    }

    // Marketing Teaser Textpic teaser, e.g. for team members
    &.card-textteaser {
        margin-bottom: 2rem;
        .card-img {
            img {
                max-width: 100%;
                height: auto;
            }
        }
        .card-body {
            padding: 1.25rem 0;
            h5 {
                font-family: $font-family-headlines;
                font-size: 1.75rem;
                font-weight: $font-weight-normal;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
            }
            .card-subtitle {
                font-weight: $font-weight-bold;
                margin-bottom: 0.5rem;
            }
        }
    }
}

// Margin bottom of last elements, based on breakpoints
.nav-teasers {
    &.nav-teasers-default {
        .nav-item:last-of-type {
            .card.card-selection {
                margin-bottom: 2rem;
            }
        }
        @include media-breakpoint-up(sm) {
            .nav-item:nth-last-of-type(-n+2) {
                .card.card-selection {
                    margin-bottom: 2rem;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .nav-item:nth-last-of-type(-n+3) {
                .card.card-selection {
                    margin-bottom: 2rem;
                }
            }
        }
    }
    &.nav-teasers-4 {
        .nav-item:last-of-type {
            .card.card-selection {
                margin-bottom: 2rem;
            }
        }
        @include media-breakpoint-up(sm) {
            .nav-item:nth-last-of-type(-n+2) {
                .card.card-selection {
                    margin-bottom: 2rem;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .nav-item:nth-last-of-type(-n+4) {
                .card.card-selection {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}

.frame-type-manta_crosslink_teaser_menu {
    .row {
        .col-sm-6.col-lg-4:last-of-type {
            .card.card-selection {
                margin-bottom: 2rem;
            }
        }
        @include media-breakpoint-up(sm) {
            .col-sm-6.col-lg-4:nth-last-of-type(-n+2) {
                .card.card-selection {
                    margin-bottom: 2rem;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .col-sm-6.col-lg-4:nth-last-of-type(-n+3) {
                .card.card-selection {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}


// TravelArea page
//
// region list, with initially hidden elements
.region-list {
    margin-bottom: 4.5rem;
    // less margin if items are hidden to show the show-more-button closer to the displayed items
    &.hidden-items {
        ul.nav.nav-cards,
        ul.nav.nav-cards-4 {
            .nav-item {
                .nav-link {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}
.item-hidden {
    display: none;
}

// Team page
//
// container for textpic teasers (= team members)
.row-textteaser {
    // display teasers in columns
    .frame-type-manta_textpic_teaser {
        @extend .col-12;
        @extend .col-md-6;
        @extend .col-lg-4;
        margin-bottom: 0;
    }
}


.content-box {
    margin-bottom: 4.5rem;

    .content-v-centered {
        display: flex;
        flex-flow: column wrap;
        padding: 2.25rem 0;

        @include media-breakpoint-up(md) {
            padding: 1rem 0 1rem 2.25rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 1rem 0 1rem 3rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 1rem 0 1rem 4.5rem;
        }

        @include media-breakpoint-up(xxl) {
            padding: 1rem 0 1rem 6.875rem;
        }
    }
    .row {
        @extend .g-0;
        @extend .mx-0;
        .col-media-left {
            @extend .col-12;
            @extend .col-md-6;
            @extend .justify-content-center;
            display: flex;
            flex-flow: column wrap;
        }
        .col-text-right {
            @extend .col-12;
            @extend .col-md-6;
            @extend .justify-content-center;
        }
    }
}

.search-offers,
.related-offers {
    margin-bottom: 4.5rem;
}
.offer-grid {
    @extend .row;

    .grid-item {
        @extend .col-12;
        @extend .col-md-6;
        @extend .col-lg-4;
    }
}