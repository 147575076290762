.map-container {
    .map-container-consentinfo {
        display: none;
    }

    &.no-consent {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: $map-placeholder-img;
        vertical-align: middle;

        .map-container-consentinfo {
            position: relative;
            display: inline;
            padding: 3rem;
            color: $black;
            text-align: center;
        }

        .gmap-item {
            display: none;
        }
    }
}
.no-consent-info {
    font-style: italic;
}