@import 'free/variables-reduced';
@import 'free/dropdown';
@import 'free/forms/form-control';
@import 'free/forms/form-select';
@import 'pro/variables-reduced';
@import 'pro/select';

@import 'pro/datepicker';


.select-wrapper {
    .select-arrow {
        &::before {
            content: "\e912";
            // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
            font-family: 'iconmanta';
        }
    }
}