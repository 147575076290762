// Variables

// Manta Custom
$map-placeholder-img: url('../img/map-placeholder.jpg');

// Color system
$black: #000000;
$gray-darker: #76787A;
$gray-dark: #CCCCCC;
$gray-light: #E9E9E9;
$gray-lighter: #F1F1F1;

// Special Manta Colors
$bottlegreen: #303E36;
$manta-red: #C3004A;
$manta-blue: #004073;
$coral: #E8DBBE;
$contact: #00594F;
$underwaterblue: #6699B8;
$palmgreen: #546B11;

$blue-bg: rgba(102, 153, 184, 0.24);
$green-bg: rgba(84, 107, 17, 0.24);
$form-bg: rgba(222, 213, 200, 0.48);

$rating-bg: $blue-bg;
$sustainability-bg:$green-bg;
$booking-bg: $form-bg;
$mantarating-bg: rgba(232, 219, 190, 0.24);

$fastbooking-form-floating-label-transform: scale(0.85) translateY(-0.5rem) translateX(-1rem);


// Bootstrap 5.x

// Primary and Secondary Colors, as defined in the design!
$primary: $bottlegreen;
$secondary: #FFF;

$container-bg-coral: rgba(232, 219, 190, 0.24);


// Options (366)
//
$enable-rounded:            true;
$enable-dark-mode:          false;


// Body (433)
$body-color:                $black;


// Links (449)
//
$link-color:                $black;
$link-decoration:           none;


// Components (528)
$border-radius:             0;
$border-radius-sm:          0;
$border-radius-lg:          0;
$border-radius-xl:          0;
$border-radius-xxl:         0;


// Typography (600)
//
$font-family-sans-serif: Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-headlines: "Roboto Condensed", 'Helvetica Neue', Arial, sans-serif;
$font-family-blackdiamond: "Black Diamond", 'Helvetica Neue', Arial, sans-serif;

$font-weight-light:           300;
$font-weight-normal:          300;
$font-weight-medium:          400;
$font-weight-semibold:        400;
$font-weight-bold:            400;
$font-weight-bolder:          500;

$headings-font-weight:        300;


// Buttons + Forms (779)
$input-btn-padding-y:         0.875rem;
$input-btn-padding-x:         1.5rem;
$input-btn-padding-y-sm:      0.75rem;
$input-btn-padding-x-sm:      1.25rem;
$input-btn-padding-y-lg:      0.875rem;
$input-btn-padding-x-lg:      1.5rem;


// Buttons (808)
$btn-border-radius:           34px;
$btn-border-radius-sm:        34px;
$btn-border-radius-lg:        34px;


// Forms (860)
$input-border-color:             #CCC;
$input-border-radius:            4px;
$input-border-radius-sm:         4px;
$input-border-radius-lg:         4px;
//$form-floating-label-transform:  scale(.85) translateY(-.5rem) translateX(0.15rem);

// Dropdowns (1226)
//
$dropdown-min-width:          15rem;


// Pagination (1282)
$pagination-font-size:        0.875rem;
$pagination-border-width:     0;


// Cards (1332)
$card-border-radius:          0;
$card-border-width:           0;
$card-cap-bg:                 #fff;
$card-cap-padding-x:          0;
$card-cap-padding-y:          0;
$card-bg:                     transparent;


// Accordion (1356)
$accordion-padding-x:               0;
$accordion-border-width:            0;
$accordion-button-active-bg:        0;
$accordion-button-active-color:     $body-color;
$accordion-button-focus-box-shadow: none;    // we don't care about accessibility :(

$accordion-icon-width:              1rem;
$accordion-icon-color:              $body-color;
$accordion-button-icon:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14' fill='#{$accordion-icon-color}'><path d='M6 14V8H0V6H6V0H8V6H14V8H8V14H6Z' fill='black'/></svg>");
$accordion-button-active-icon:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14' align='center' fill='#{$accordion-icon-color}'><rect width='14' height='2' y='6' x='0' fill='black'/></svg>");


// Offcanvas (1713)
$offcanvas-horizontal-width:    320px;
