body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;

    @media screen and (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }
}

.container-default {
    @extend .container-lg;

    .col-indent {
        @extend .col-12;
        @extend .col-xl-10;
        @extend .offset-xl-1;
    }
}

.highlight-platinum {
    background-color: $container-bg-coral;
    padding: 2rem;
    margin-bottom: 4.5rem;
    .frame-default {
        margin-bottom: 0;
    }
    @include media-breakpoint-up(md) {
        padding: 4.5rem;
    }
}