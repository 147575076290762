@media print
  .alert-info,
  .header-navs,
  .main-nav,
  .breadcrumbs-row,
  .header-navbar,
  .anchor-nav,
  #offer,
  #more-offers,
  #map,
  .footer-breadcrumbs,
  .medialink-bar,
  .slick .text-overlay,
  .factbox .btn-primary,
  .favorites-link,
  .highlightbox--price,
  footer,
  .cookie-consent,
  .section-form
    display: none !important

  .header
    padding-bottom: 0.5rem

  .slick-track,
  .slick-slide
    width: 100% !important

  .slick-slide::after
    display: none
    background: none

  h1
    padding-top: 10mm !important

  .page-content
    padding: 0 !important

  .itinerary-desc.js-reduced
    max-height: none
    overflow: visible
  .js-fullitinerary
    display: none !important

  #media
    .col-md-6
      flex: none
      width: 49%
      float: left

//  html, body
//    width: 210mm
//    height: 297mm
