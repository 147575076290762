$backdrop-opacity: 0.9;
$lg-border-radius-base: 0px;
$lg-theme-highlight: $manta-red;

@import 'lightgallery/scss/lightgallery';
@import 'lightgallery/scss/lg-thumbnail';
@import 'lightgallery/scss/lg-video';

// Overwrite lg-styles
.lg-outer {
    .lg-thumb-item {
        border-radius: 0;
        @media (min-width: 768px) {
            border-radius: 0;
            border: 1px solid #fff;
        }
    }
}
.lg-toolbar .lg-download.lg-icon {
    display: none;
}

// Styles for manta component
.light-gallery {
    a.nav-link {
        &.youtube-videos {
            cursor: pointer;
        }
    }
}