.rating {
    position: relative;
    // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
    font-family: 'iconmanta';
    -webkit-font-smoothing: antialiased;
    font-size: .75rem;
    display: inline-block;

    &:before {
        content: "\e937 \e937 \e937 \e937 \e937 \e937";
    }
    &.r5:before {
        content: "\e936 \e937 \e937 \e937 \e937 \e937";
    }
    &.r10:before {
        content: "\e935 \e937 \e937 \e937 \e937 \e937";
    }
    &.r15:before {
        content: "\e935 \e936 \e937 \e937 \e937 \e937";
    }
    &.r20:before {
        content: "\e935 \e935 \e937 \e937 \e937 \e937";
    }
    &.r25:before {
        content: "\e935 \e935 \e936 \e937 \e937 \e937";
    }
    &.r30:before {
        content: "\e935 \e935 \e935 \e937 \e937 \e937";
    }
    &.r35:before {
        content: "\e935 \e935 \e935 \e936 \e937 \e937";
    }
    &.r40:before {
        content: "\e935 \e935 \e935 \e935 \e937 \e937";
    }
    &.r45:before {
        content: "\e935 \e935 \e935 \e935 \e936 \e937";
    }
    &.r50:before {
        content: '\e935 \e935 \e935 \e935 \e935 \e937';
    }
    &.r55:before {
        content: "\e935 \e935 \e935 \e935 \e935 \e936";
    }
    &.r60:before {
        content: "\e935 \e935 \e935 \e935 \e935 \e935";
    }
    .rating-inner {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        overflow: hidden;
        height: 1rem;
        white-space: nowrap;

        &:before {
            content: "\e937 \e937 \e937 \e937 \e937 \e937";
            display: block;
            overflow: hidden;
            white-space: nowrap;
            height: 1rem;
        }
    }
}