.alert-bar {
    display: none;
    padding: 0.5rem 0;
    color: $white;
    background-color: $manta-blue;
    font-size: 14px;

    a {
        color: $white;
        text-decoration: underline;
    }

    >.container-lg {
        text-align: center;
    }
}